// Определение переменных
$triangle-size: 6px;
$triangle-color: black;
$triangle-color-inverted: white;
$triangle-transition-delay: 0.5s;

.accordion {
  padding-left: 20px;
  padding-right: 20px;

  &.inverted {
    .accordion-title {
      .triangle {
        border-top: $triangle-size solid $triangle-color-inverted; // Использование переменной
      }

      .title-content {
        color: $triangle-color-inverted; // Использование переменной
      }
    }
  }

  .triangle_header {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .accordion-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 14px;


    .triangle-container {
      display: flex;
      justify-content: center; // Центрирование треугольника по горизонтали
      align-items: center; // Центрирование треугольника по вертикали
      padding: 5px; // Установка отступов вокруг треугольника
      background-color: transparent; // Начальный цвет фона
      border-radius: 3px;

      &:hover {
        background-color: rgba(157, 157, 157, 0.61); // Фон при наведении
      }

      .triangle {
        width: 0;
        height: 0;

        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-top: $triangle-size solid $triangle-color;
        transition: transform $triangle-transition-delay ease;
        transform: rotate(-90deg);

        &.open {
          transform: rotate(0deg);
        }

        &.inverted {
          border-top: $triangle-size solid $triangle-color-inverted;
        }
      }
    }

    .title-content {
      margin-left: 10px;
      width: max-content;
      // Ваши стили для заголовка...
      h2 {
        margin: 0;
        padding: 0.5em;
      }
    }
  }

  .accordion-content {
    margin-bottom: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height $triangle-transition-delay ease;

    &.expanded {
      max-height: max-content;
    }
  }
}
