.input-number {
  width: 60px;
  margin-left: 10px;
  height: 38px;

  input {
    padding: 5px!important;
  }
}

.input-search {
  margin-right: 15px;

  input {
    background-color: #F0F0F0!important;
  }
}

.column-button-max {
  max-width: 400px !important;
  min-width: 400px !important;
}

.column-button-min {
  max-width: 346px !important;
  min-width: 346px !important;
}

.column-segment-grid {
  min-width: 250px !important;
  max-width: 250px !important;
}

.icon-delete {
  transition: opacity 0.2s ease-in;
  color: #676060;
  margin-left: 10px !important;
  opacity: .1 !important;
  cursor: pointer;

  &:hover {
    opacity: 1 !important;
  }
}