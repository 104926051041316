@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sacramento&display=swap');

@font-face {
  font-family: 'Days One'; /*You can use whatever name that you want*/
  src: url('./fonts/days.ttf');
}

h1, h2, h3, h4, h5 {
  font-family: 'Days One', Arial, Helvetica, sans-serif !important;
  font-weight: 500;
}


*:not(.icon, h1, h2, h3, h4, h5) {
  font-family: 'Rubik', Arial, Helvetica, sans-serif !important;
}

.exp-token {
  font-size: 0.8em;
}

.ui.header {
  font-family: 'Days One', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: 500;
}

.table-body-word-wrap {
  td {
    word-break: break-all!important;
  }
}

body {
  //background-color: black !important;
}

.App {
  //text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sidebar-pusher-div {
  margin-left: 180px;
  padding: 15px 10px 10px;
}

.ui.left.visible.sidebar, .ui.right.visible.sidebar {
  box-shadow: none!important;
}

.ui[class*="very thin"].left.sidebar, .ui[class*="very thin"].right.sidebar {
  width: 65px!important;
}


.main-menu-item-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    margin-top: 5px;
  }
}

.video-modal {
    margin-top: 300px!important;
}

.video-manual {
  padding: 0!important;
}

.video-manual-p {
  font-size: 0.89em;
  font-weight: bold;
}


.modal-dialog {
    margin-top: 500px!important;
}



.App-link {
  color: #61dafb;
}

.ui.dimmer {
  justify-content: normal!important;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homeSideBar {
  min-height: 100vh;
  width: 200px !important;

  div {
    display: flex !important;
    align-items: center !important;
    height: 65px;

    h3 {
      margin: 0 !important;
    }
  }

  a {
    display: flex !important;
    align-items: center !important;
    height: 65px;
  }
}

.advanced-menu-icon-back {
  height: max-content;
  width: max-content;

  i {
    cursor: pointer;
    margin-left: 21px!important;
  }
}

.mainSideBar {
  a {
    padding: 10px 0 10px 0 !important;
    align-items: center !important;
    min-width: 60px !important;
    height: 65px !important;
    justify-content: center;
    display: flex !important;

    div {
      cursor: pointer;


      label {
        cursor: inherit;
      }

    }
  }
}


.itemAccountsMenu {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;

  p.account {
    margin-bottom: 0;
  }

}

p.subtext {
  font-size: .7em;
  color: #a7abac;

  &.red {
    color: red;
  }
}

.buttons-connect {
  cursor: auto;
  //border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-link {
  box-shadow: none !important;
  border: none;
  padding-left: 0px;
  padding-right: 15px;
  color: #2185d0;
  cursor: pointer;
  background-color: transparent;
}

.button-link:disabled {
  color: #706f6f;
}

.button-link:not(:disabled):hover {
  color: #1678c2;
}

.button-link:not(:disabled).inverted {
  color: #ffffff;
}

.button-link:not(:disabled).inverted:hover {
  color: #54c8ff;
}


.standard-input {
  min-width: 300px;
}

.segment-grid .column {
  min-width: 300px !important;
  max-width: 300px;
}


.ui.grid .row+.ui.divider {
  margin: 0 !important;
}

.date-time-picker-300 {
  width: 240px;
  height: 38px;
  color: black;
  background-color: white;
  border-radius: 5px;
  //z-index: 200000;
}

.react-datetime-picker__calendar, .react-datetime-picker__clock {
  z-index: 2;
}

.react-datetime-picker__wrapper {
  border: 1px solid #22242626;
  display: flex;
  flex-grow: 1;
  border-radius: 4px;
  flex-shrink: 0;
}

.react-date-picker__wrapper {
  border-radius: 5px;
  border-color: #54c8ff;
}

.react-date-picker__calendar {
  z-index: 3;
  .react-calendar {
    border-color: #54c8ff;
  }
}

.ui.fitted.toggle.checkbox {
  margin-right: 15px;
  &.dark {
    input[type="checkbox"]:not(:checked) + label {
      background-color: #737373 !important;
      border-radius: 10px;
      width: 50px;
    }
  }
}

.ui.slider.checkbox {
  margin-top: 10px;
}

.ui.slider.checkbox input:focus:checked~.box:before,
.ui.slider.checkbox input:focus:checked~label:before {
    background-color: #2185d0 !important;

}

.ui.slider.checkbox input:checked~.box,
.ui.slider.checkbox input:checked~label {
  &:before, :hover, :focus {
    background-color: #2185d0 !important;
  }
}

  .ui.slider.checkbox.dark .box,
  .ui.slider.checkbox.dark label {
  &:before, :hover {
    background-color: #737373 !important;
  }
}

.status-success {
  color: green;
}

.status-error {
  color: red;
}


.right-aligned-dropdown {
  .dropdown-item-content {
    display: flex;
    justify-content: space-between;
  }

  .item-count {
    margin-left: 10px; // Это автоматически выровняет число по правому краю
  }
}
