$width: 240px;

.select-container {
  align-items: center;
 // width: $width;
  position: relative;
  border: 1px solid rgba(34, 36, 38, .15);
  background-color: white;

  display: flex;
  flex-wrap: nowrap; /* Позволяет элементам переноситься на новую строку */
  flex-direction: row;
  justify-content: space-between;
  min-height: 36px; /* Минимальная высота контейнера */
  padding: 0;
  border-radius: 4px;
  height: max-content;

  &:focus-within {
    border-color: #85b7d9;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

  }

  .single-input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 5px 10px 5px 10px;
    background: #fff;
    border: none;
    color: rgba(0, 0, 0, .87);
    box-shadow: none;
  }

  .select-arrow-div {
    cursor: pointer; // Курсор в виде пальца
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px; // Ширина достаточная для удобного клика
 //   background-color: #0d71bb;
  }

  .select-arrow {
    position: absolute;
    right: 10px; // Отступ справа
    top: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black; // Цвет треугольника
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .select-arrow.open {
    transform: translateY(-50%) rotateX(180deg);
  }





  .multi-select-input {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center; /* Элементы выровнены вверху */
    flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку */

    min-height: 36px; /* Минимальная высота контейнера */
    border: none; /* Стиль границы */
    padding: 5px 5px 5px 5px;



    .selected-item {
      cursor: pointer;
      background-color: #e4e4e4; /* Пример фона для выбранных элементов */
      margin: 1px 5px 0 0; /* Отступы вокруг элементов */
      padding: 1px 6px;
      border-radius: 2px;
      color: black;
      overflow-wrap: anywhere;
    }
  }



  .search-input {
    height: 15px;
    border: none;
    outline: none;
    margin: 5px 5px 0 0; /* Отступы вокруг поля ввода */
    background: transparent;
  }



  .select-dropdown {
    background-color: white;
    cursor: pointer;
    color: black;
    max-height: 300px;
    overflow-y: auto;
   // width: $width;// Такая же ширина, как у input
    // Дополнительные стили для выпадающего списка
    position: absolute;
    margin-left: -1px;
    margin-top: 1px;

    z-index: 1000;
    //  top: 36px;
    border: 1px solid #85b7d9;
    border-top: 1px solid transparent;
    border-bottom-right-radius: .28571429rem;
    border-bottom-left-radius: .28571429rem;

    .dropdown-item {
      padding: 5px 10px 5px 10px;
      text-align: left;
      &:hover {
        background-color: gray;
      }
    }
  }
}


