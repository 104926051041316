$width: 400px;
.sidebar {
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: $width;
  height: 100%;
  background-color: #fff; // Или любой другой цвет
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);

  z-index: 1000; // Чтобы панель была над другими элементами

  &.right {
    transition: right 0.5s ease; // Плавная анимация
    right: -$width; // Изначально скрыта
    &.open {
      right: 0; // Панель выезжает
    }
  }

  &.left {
    transition: left 0.5s ease; // Плавная анимация
    left: -$width; // Изначально скрыта
    &.open {
      left: 0; // Панель выезжает
    }
  }
}
